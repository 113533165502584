import { Layout } from "./components/Layout";
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Work from "./pages/Work";
import { TransitionProvider } from "./context/TransitionContext";
import TransitionComponent from "./components/Transition";

function App() {
  return (
    <Layout>
      <TransitionProvider>
        <Routes>
          <Route
            path="/"
            element={
              <TransitionComponent>
                <MainPage />
              </TransitionComponent>
            }
          />
          <Route
            path="/work"
            element={
              <TransitionComponent>
                <Work />
              </TransitionComponent>
            }
          />
        </Routes>
      </TransitionProvider>
    </Layout>
  );
}

export default App;
