import React, { useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { Navbar } from "./Navbar";

export const Layout = ({ children }) => {
  const containerRef = useRef(null);

  return (
    <React.Fragment>
      <div className="container  ">
        <Navbar />
        <LocomotiveScrollProvider
          options={{
            smooth: true,
          }}
          containerRef={containerRef}
        >
          <main data-scroll-container ref={containerRef}>
            {children}
          </main>
        </LocomotiveScrollProvider>
      </div>
    </React.Fragment>
  );
};
