import uralice from "../assets/images/web-sites/uralice.webp";
import ref193 from "../assets/images/web-sites/ref193.webp";
import etalon from "../assets/images/web-sites/etalon.webp";
import askerovaMarina from "../assets/images/web-sites/askerova-marina.webp";

const projects = [
  {
    name: "Уралайс - продажа холодильного оборудования",
    description:
      "Разработка полноценного интернет магазина по продаже холодильного оборудования",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
      {
        name: "mongodb",
        color: "pink-text-gradient",
      },
    ],
    image: uralice,
    code_link: "https:/www.uralice.ru",
    code_git: "https://github.com/1982ruff/uralice",
  },
  {
    name: "РЕФ193.рф Рефрижератор в Сочи",
    description:
      "Персональный сайт-лендинг курьерской доставки продуктов в температурном режиме в Сочи от -18° до +12°",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: ref193,
    code_link: "https://реф193.рф/",
    code_git: "https://github.com/1982ruff/ref193.ru",
  },
  {
    name: "Сайт стоматологической студии Эталон",
    description: "Сайт для стоматологической студии",
    tags: [
      {
        name: "react",
        color: "red-text-gradient",
      },
      {
        name: "nextjs",
        color: "green-text-gradient",
      },
      {
        name: "sanity",
        color: "pink-text-gradient",
      },
    ],
    image: etalon,
    code_link: "https://etalon.vercel.app/",
    code_git: "https://github.com/1982ruff/etalon",
  },
  {
    name: "Аскерова Марина - Таролог и энергопрактик",
    description: "Личный сайт и блог практикующего таролога. ",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "javascript",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: askerovaMarina,
    code_link: "http://askerova-marina.ru",
    code_git: "https://github.com/1982ruff/taro",
  },
];
export { projects };
