import React from "react";

export const Footer = () => {
  return (
    <footer className="container mx-auto h-[300px] max-w-7xl pb-4">
      <div className="grid w-full grid-cols-2 gap-10 px-10 pt-10 md:grid-cols-4">
        <div className="flex flex-col gap-1 border-t border-yellow-400">
          <span className="mt-2 text-xs font-light text-neutral-400">
            Версия сайта #2
          </span>
          <p className="font-Manrope text-sm text-neutral-700">2023 Edition</p>
        </div>

        <div className="flex flex-col gap-1 border-t border-yellow-400">
          <span className="mt-2 text-xs font-light text-neutral-400">
            Все права защищены
          </span>
          <p className="font-Manrope text-sm text-neutral-700">
            Rafail 'RUFF' Askerov
          </p>
        </div>

        <div className="flex flex-col gap-1 border-t border-yellow-400">
          <span className="mt-2 text-xs font-light text-neutral-400">
            Написать письмо
          </span>
          <p className="font-Manrope text-sm text-neutral-700">
            <a
              href="mailto:1982ruff@gmail.com"
              className="hover:text-yellow-400"
            >
              1982ruff@gmail.com
            </a>
          </p>
        </div>

        <div className="flex flex-col gap-1 border-t border-yellow-400">
          <span className="mt-2 text-xs font-light text-neutral-400">
            Социальные сети
          </span>
          <p className="font-Manrope text-sm text-neutral-700">
            <div className="flex flex-wrap gap-1">
              <a href="http://t.me/ruffmusic" className="hover:text-yellow-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 "
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19c-.14.75-.42 1-.68 1.03c-.58.05-1.02-.38-1.58-.75c-.88-.58-1.38-.94-2.23-1.5c-.99-.65-.35-1.01.22-1.59c.15-.15 2.71-2.48 2.76-2.69a.2.2 0 0 0-.05-.18c-.06-.05-.14-.03-.21-.02c-.09.02-1.49.95-4.22 2.79c-.4.27-.76.41-1.08.4c-.36-.01-1.04-.2-1.55-.37c-.63-.2-1.12-.31-1.08-.66c.02-.18.27-.36.74-.55c2.92-1.27 4.86-2.11 5.83-2.51c2.78-1.16 3.35-1.36 3.73-1.36c.08 0 .27.02.39.12c.1.08.13.19.14.27c-.01.06.01.24 0 .38z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/ruffmusic/"
                className="hover:text-yellow-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-7 w-7 "
                >
                  <path
                    fill="currentColor"
                    d="M12,3.8c2.67,0,2.986.01,4.041.058A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.552,5.552,0,0,1,.344,1.857C20.188,9.014,20.2,9.33,20.2,12s-.01,2.986-.058,4.041A5.552,5.552,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.552,5.552,0,0,1-1.857.344c-1.054.048-1.371.058-4.041.058s-2.987-.01-4.041-.058A5.552,5.552,0,0,1,6.1,19.8a3.31,3.31,0,0,1-1.9-1.9,5.552,5.552,0,0,1-.344-1.857C3.812,14.986,3.8,14.67,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.2,6.1,3.31,3.31,0,0,1,6.1,4.2,5.552,5.552,0,0,1,7.959,3.86C9.014,3.812,9.33,3.8,12,3.8M12,2c-2.716,0-3.056.012-4.123.06a7.355,7.355,0,0,0-2.427.465A5.106,5.106,0,0,0,2.525,5.45,7.355,7.355,0,0,0,2.06,7.877C2.012,8.944,2,9.284,2,12s.012,3.056.06,4.123a7.355,7.355,0,0,0,.465,2.427A5.106,5.106,0,0,0,5.45,21.475a7.355,7.355,0,0,0,2.427.465C8.944,21.989,9.284,22,12,22s3.056-.011,4.123-.06a7.355,7.355,0,0,0,2.427-.465,5.106,5.106,0,0,0,2.925-2.925,7.355,7.355,0,0,0,.465-2.427c.048-1.067.06-1.407.06-4.123s-.012-3.056-.06-4.123a7.355,7.355,0,0,0-.465-2.427A5.106,5.106,0,0,0,18.55,2.525a7.355,7.355,0,0,0-2.427-.465C15.056,2.012,14.716,2,12,2Z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.468A3.333,3.333,0,1,1,15.333,12,3.333,3.333,0,0,1,12,15.333Z"
                  ></path>
                  <circle
                    fill="currentColor"
                    cx="17.338"
                    cy="6.662"
                    r="1.2"
                  ></circle>
                </svg>
              </a>
            </div>
          </p>
        </div>
      </div>
    </footer>
  );
};
