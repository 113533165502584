import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { FiArrowUpRight, FiChevronRight } from "react-icons/fi";
import SectionWrapper from "../hoc/SectionWrapper";
import { slideIn } from "../utils/motion";
import { gsap } from "gsap";

const Contact = () => {
  const el = useRef();

  const mouseEnter = () => {
    gsap.to(el.current, {
      duration: 0.5,
      rotation: "-=45",
      yoyo: true,
      backgroundColor: "#22c55e",
    });
  };

  const mouseLeave = () => {
    gsap.to(el.current, {
      duration: 0.5,
      rotation: "+=45",
      yoyo: true,
      backgroundColor: "#facc15",
    });
  };
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: form.name,
          to_name: "Rafail RUFF Askerov",
          from_email: form.email,
          to_email: "crashmen@mail.ru",
          message: form.message,
          email: form.email,
          phone: form.phone,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          setLoading(false);
          alert("Спасибо за Ваше сообщение! 😉");

          setForm({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ой... что-то пошло не так! 😟");
        }
      );
  };

  return (
    <section className="container mx-auto w-full max-w-7xl ">
      <motion.div
        variants={slideIn("down", "spring", 0.5, 1)}
        className="w-full border-b px-10 "
      >
        <h3 className="font-Manrope text-xl  font-medium uppercase tracking-wide text-yellow-400 antialiased">
          СВЯЖИТЕСЬ СО МНОЙ
        </h3>
      </motion.div>

      <div className="flex w-full flex-col-reverse items-center gap-10 overflow-hidden px-4 pb-6 xl:mt-12 xl:flex-row">
        <motion.div
          variants={slideIn("up", "tween", 0.2, 0.6)}
          className="mt-4 w-full flex-col space-y-2 rounded-2xl bg-slate-100 px-4 shadow-xl"
        >
          <div className=" mt-10 grid w-full grid-cols-1 md:grid-cols-2 ">
            <div className=" flex flex-col items-center justify-center space-y-10 ">
              <h3 className="font-Manrope text-3xl font-extrabold text-slate-700 lg:text-5xl">
                Будем на связи 😉
              </h3>
              <p className="mt-10 w-full max-w-lg text-start text-lg text-slate-700 md:text-xl lg:text-start lg:text-2xl">
                Напишите мне если у Вас есть интересное предложение по
                воплощению Вашей идеи в виде красивого сайта !
              </p>
              <div className="flex flex-col items-center gap-1 md:hidden">
                <a href="mailto:1982ruff@gmail.com" className="">
                  <div className="flex h-16 w-16 items-center justify-center rounded-full bg-yellow-400">
                    <FiArrowUpRight
                      size={30}
                      className="rotate-45 text-white"
                    />
                  </div>
                </a>
                <span className="text-center font-light text-slate-500 md:text-xl lg:text-2xl">
                  Отправить e-mail
                </span>
              </div>
              <a
                href="mailto:1982ruff@gmail.com"
                className="group hidden cursor-pointer flex-col items-center justify-center space-y-2 place-self-center md:flex"
              >
                <motion.div
                  ref={el}
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                  initial={{ borderRadius: 100 }}
                  className="parent"
                >
                  <div className="child">
                    <FiArrowUpRight
                      size={30}
                      className="rotate-45 text-white"
                    />
                  </div>
                </motion.div>
                <span className="text-center font-light text-slate-500 md:text-xl lg:text-2xl">
                  Отправить e-mail
                </span>
              </a>{" "}
            </div>

            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="mb-8 mt-2 flex flex-col gap-8"
            >
              <span className="mt-10 text-center text-xl font-light text-slate-500 lg:text-2xl">
                Отправить через форму
              </span>
              <label className="flex flex-col">
                <span className="mb-4 text-base font-light text-slate-500">
                  Ваше имя:
                </span>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Иван Иванович"
                  className="rounded-lg border-none bg-white px-6 py-4 font-medium text-black outline-none placeholder:font-light placeholder:text-slate-300"
                />
              </label>
              <label className="flex flex-col">
                <span className="mb-4 text-base font-light text-slate-500">
                  Ваш контактный телефон:
                </span>
                <input
                  type="phone"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  placeholder="+79991238899"
                  className="rounded-lg border-none bg-white px-6 py-4 font-medium text-black outline-none placeholder:font-light placeholder:text-slate-300"
                />
              </label>
              <label className="flex flex-col">
                <span className="mb-4 text-base font-light text-slate-500">
                  Ваша электронная почта:
                </span>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="yandex@yandex.ru"
                  className="rounded-lg border-none bg-white px-6 py-4 font-medium text-black outline-none placeholder:font-light placeholder:text-slate-300"
                />
              </label>
              <label className="flex flex-col">
                <span className="mb-4 text-base font-light text-slate-500">
                  Ваше сообщение:
                </span>
                <textarea
                  rows={7}
                  name="message"
                  value={form.message}
                  onChange={handleChange}
                  placeholder="Описание Вашего предложения "
                  className="rounded-lg border-none bg-white px-6 py-4 font-medium text-black outline-none placeholder:font-light placeholder:text-slate-300"
                />
              </label>
              <button
                type="submit"
                className="group flex w-fit items-center rounded-xl bg-yellow-400 px-6 py-2 text-base font-light text-white outline-none transition-colors duration-200 ease-in-out hover:bg-[#22c55e]"
              >
                {loading ? "Отправляем..." : "Отправить"}
                <FiChevronRight
                  size={25}
                  className="ml-1 mt-1 transition duration-200 ease-in-out group-hover:translate-x-2"
                />
              </button>
            </form>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SectionWrapper(Contact, "contact");
