import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import TextAnimation from "../components/TextAnimation";
import logoYellow from "../../src/assets/svg/logo.svg";

export const Navbar = ({ Component, pageProps, router }) => {
  const [hover, setHover] = useState(false);

  const activeStyles = {
    color: "#eab308",
  };

  const hoverDot =
    "after:absolute after:h-[9px] after:w-[9px] after:-bottom-4 after:left-[50%] after:scale-0 after:bg-yellow-500 after:rounded-full after:hover:scale-100 after:transition";

  return (
    <header className="relative z-50 flex items-center py-4 px-10">
      <nav className="flex w-full items-center justify-between px-10">
        <Link to="/">
          <div className="flex h-[100px] w-full items-center justify-center transition-all duration-300 ease-in-out">
            <div
              className="w-[40px] md:w-[60px]"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <img src={logoYellow} alt="" className=" " />
            </div>
            <div className="">
              {hover ? (
                <div className=" ">
                  <TextAnimation />
                </div>
              ) : (
                <div className="hidden">
                  <TextAnimation />
                </div>
              )}
            </div>
          </div>
        </Link>

        <ul className="">
          <li className="">
            <NavLink
              to="/work"
              className={`relative text-base font-light text-slate-700 md:text-xl ${hoverDot}`}
              style={({ isActive }) => (isActive ? activeStyles : undefined)}
            >
              Портфолио
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};
