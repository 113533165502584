import React from "react";

const TextAnimation = () => {
  return (
    <div className="flex h-[60px] items-center justify-center md:h-[100px]">
      <div className="flex h-[40px] py-[20px] text-lg uppercase tracking-[1px] ">
        <div className="ml-[8px] grid overflow-hidden text-yellow-400">
          <span className="animate-scroll "> на главную</span>
        </div>
      </div>
    </div>
  );
};

export default TextAnimation;
