import React from "react";
import { motion } from "framer-motion";
import ReactCurvedText from "react-curved-text";
import { Link } from "react-scroll";

const ArrowDown = () => {
  const newLocal = (
    <ReactCurvedText
      width="300"
      height={300}
      cx="150"
      cy={150}
      rx={58}
      ry="58"
      startOffset="0"
      reversed={false}
      text="be positive be productive be awesome 🤟 "
      textProps={{ style: { fontSize: "20" } }}
      textPathProps={{ fill: "#fbbf24" }}
      tspanProps={{ dy: "14" }}
      svgProps={{ className: "animate-rotate" }}
    />
  );
  return (
    <section className="absolute left-[50%] bottom-20 z-50 hidden w-fit items-center justify-center lg:block">
      <div className=" translate-y-44 -translate-x-[131px]">{newLocal}</div>

      <Link to="about" spy={true} smooth={true}>
        <div className="group flex h-[50px] w-[40px] cursor-pointer items-start justify-center rounded-3xl border-2 border-yellow-400 p-2 transition-colors duration-200 ease-in-out hover:bg-yellow-400">
          <motion.div
            animate={{ y: [0, 24, 0] }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: "loop",
            }}
            className="rounded-full pb-2 text-yellow-400  transition-colors duration-200 ease-in-out  hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              className="-mr-0.3 -mt-3"
            >
              <path
                fill="currentColor"
                d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4l-6 6Z"
              />
            </svg>
          </motion.div>
        </div>
      </Link>
    </section>
  );
};

export default ArrowDown;
