import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { StaggerTextReveal } from "stagger-text-reveal-animation";
import { GoLinkExternal, GoMarkGithub } from "react-icons/go";
import { urlFor, client } from "../client";
import Skills from "../components/Skills";
import { Footer } from "../components/Footer";

const Work = () => {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    const query = '*[_type == "myworks"]';

    client.fetch(query).then((data) => {
      setWorks(data);
    });
  }, []);

  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "#facc15" });
  };

  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "#000" });
  };

  return (
    <section className="relative mx-auto max-w-full ">
      <div className=" mx-auto max-w-7xl space-y-5 ">
        <h4 className="mt-10 px-10 py-3 text-end font-Manrope text-[32px] font-semibold text-yellow-400 ">
          <StaggerTextReveal
            text={"Мои проекты"}
            duration={1}
            fontSize={40}
            height={60}
            fontWeight={"light"}
            direction={"down"}
            stagger={80}
          />
        </h4>
        <h3 className="px-10 text-xl font-light text-slate-500">
          При создании сайтов я использую следующие технологии и фреймворки
        </h3>
      </div>

      {/* skills */}

      <div className="mb-6 w-full">
        <Skills />
      </div>

      <motion.div
        initial={{ opacity: 0, translateX: 600 }}
        exit={{ opacity: 0.5, translateX: 200 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        className="px-6"
      >
        {works.map((work, index) => (
          <div
            className="mt-4 flex flex-col items-center justify-center gap-2 md:gap-8 lg:flex-row "
            key={index}
          >
            <div className="lg:flex-cols flex w-full place-items-end items-center justify-center gap-6 border-t border-yellow-400 py-4 lg:w-fit lg:flex-col">
              <a href={work.link} className="" target="_blank" rel="noreferrer">
                <div
                  onMouseEnter={onEnter}
                  onMouseLeave={onLeave}
                  className="text-black/80"
                >
                  <GoLinkExternal size={35} />
                </div>
              </a>
              <a
                href={work.github}
                className=""
                target="_blank"
                rel="noreferrer"
              >
                <div
                  onMouseEnter={onEnter}
                  onMouseLeave={onLeave}
                  className="text-black/80"
                >
                  <GoMarkGithub size={35} />
                </div>
              </a>
            </div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className={`relative w-full rounded-2xl lg:w-[600px]
              ${work.status === "Сдан клиенту" ? "cursor-progress" : ""}
              `}
            >
              <img
                src={urlFor(work.imageurl)}
                alt={work.name}
                className="linear h-[350px] w-full rounded-2xl object-cover object-top transition-all duration-[10s] hover:object-bottom"
              />
              <div
                className={`absolute right-0 top-0 overflow-hidden rounded-bl-xl rounded-tr-2xl px-4 py-1 text-xs font-light  ${
                  work.status === "Сдан клиенту"
                    ? "bg-green-400 text-black"
                    : "bg-yellow-400 text-black"
                } 
                `}
              >
                {work.status}
              </div>
            </motion.div>

            <div className="flex w-full flex-col justify-center place-self-start lg:w-[500px] ">
              <div className="">
                <span className="text-sm font-extralight">Название сайта:</span>
                <h4 className="text-xl font-semibold">{work.name}</h4>
              </div>
              <div className="mt-4">
                <span className="text-sm font-extralight">Описание:</span>
                <p className="">{work.description}</p>
              </div>

              <div className="mt-4">
                <span className="text-sm font-extralight">Что сделано:</span>
                <p className="">{work.whatmade}</p>
              </div>

              <div className="mt-4">
                <span className="text-sm font-extralight">Сложность:</span>
                <p className="">{work.hardness}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
      <Footer />
    </section>
  );
};

export default Work;
