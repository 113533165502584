import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "v7qkcan0",
  dataset: "production",
  apiVersion: "2023-03-22",
  useCdn: true,
  token:
    "skDoOjITIzrXW8Y5u9NjXZoe6L5gaSDAYyw3LiOZZAAPUUkIBuxhtvsOLGsBNoMT0aBPsiPMz01svv2BmPCeiRp1vASCt965xair1rCsZ8JOLCj7XmJI5bBn9GoAhlwirk5tBW6l4qXJgBetVGjuaANJqZO3NnTvw3QBcuNjDNMrhI3Mgd2N",
  ignoreBrowserTokenWarning: true,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
