import React from "react";
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../../utils/motion";
import SectionWrapper from "../../hoc/SectionWrapper";
import Tilty from "react-tilty";

import webDevelopSm from "../../assets/images/hero/web-develop.avif";
import uxUiSm from "../../assets/images/hero/ux-ui.avif";
import musicSm from "../../assets/images/hero/music.avif";
import droneSm from "../../assets/images/hero/drone.avif";

import avatarMe from "../../assets/images/avatar.avif";

const ServiceCard = ({ title, image, imageSm, link }) => {
  return (
    <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
      <motion.div
        variants={fadeIn("down", "tween", 0.2, 0.75)}
        className=" w-[200px] rounded-[20px] bg-yellow-400 p-[1px] shadow-lg md:w-[280px] lg:h-[280px] lg:w-[300px]"
      >
        <motion.div
          variants={fadeIn("right", "tween", 0.5, 0.75)}
          options={{ max: 45, scale: 1, speed: 450 }}
          className="flex min-h-[200px] flex-col items-center justify-evenly rounded-[20px] px-4 py-3  lg:px-12 lg:py-3"
        >
          <a href={link} className=" cursor-pointer">
            <picture>
              <img
                loading="lazy"
                srcset={`${imageSm}?width=100 100w, ${imageSm}?width=200 200w, ${imageSm}?width=400 400w, ${imageSm}?width=800 800w`}
                alt={title}
                className="hover:scale-115 h-full w-full"
              />
            </picture>
          </a>
          <p className="text-center text-sm text-neutral-700 md:text-lg">
            {title}
          </p>
        </motion.div>{" "}
      </motion.div>
    </Tilty>
  );
};

const services = [
  {
    id: 1,
    title: "Web developer",

    imageSm: webDevelopSm,
    link: "/work",
  },
  {
    id: 2,
    title: "UX/UI Designer",

    imageSm: uxUiSm,
    link: "/work",
  },
  {
    id: 3,
    title: "Music Production & DJ",

    imageSm: musicSm,
    link: "https://soundcloud.com/ruffmusic",
  },
  {
    id: 4,
    title: "Aerial Foto & Video",

    imageSm: droneSm,
    link: "https://youtube.com/1982ruff",
  },
];

const About = () => {
  return (
    <section id="about" className="container mx-auto w-full max-w-7xl">
      <motion.div
        variants={fadeIn("right", "spring", 0.5, 0.75)}
        className="space-y-4  md:space-y-10 lg:space-y-10"
      >
        <div className="w-full border-b px-10 pb-2">
          <h3 className="font-Manrope text-xl font-medium uppercase tracking-wide text-yellow-400 antialiased sm:text-xl md:text-2xl">
            ИНФОРМАЦИЯ ОБО МНЕ
          </h3>
        </div>

        <div className="grid w-full grid-cols-1 place-items-center items-start px-10 md:grid-cols-2 md:items-center lg:h-[400px] ">
          <motion.p
            variants={textVariant()}
            className="w-full text-start text-lg text-slate-700 md:text-xl lg:text-start lg:text-2xl"
          >
            Привет, я&nbsp;
            <span className=" font-semibold text-yellow-400">RUFF</span>
            &nbsp;&mdash; web разработчик и&nbsp;UI/UX дизайнер. Мне нравится
            создавать красивые, функциональные и&nbsp;ориентированные
            на&nbsp;пользователя сайты. На&nbsp;текущий момент я&nbsp;занимаюсь
            веб-разработкой и&nbsp;мое дополнительное хобби&nbsp;&mdash; видео
            и&nbsp;музыка
          </motion.p>

          <div className="relative hidden w-[200px] rounded-full  md:flex lg:w-[350px]">
            <img
              loading="lazy"
              src={avatarMe}
              alt=""
              className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            />
          </div>
        </div>

        <motion.div className="mt-10 grid grid-cols-1 gap-4 px-4 xs:grid-cols-2 sm:grid-cols-2 md:gap-10 lg:grid-cols-4">
          {services.map((service, index) => (
            <ServiceCard key={service.title} index={index} {...service} />
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default SectionWrapper(About, "about");
