import React, { useEffect, useState } from "react";
import { fadeIn, textVariant } from "../../utils/motion";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import SectionWrapper from "../../hoc/SectionWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const query = '*[_type == "testimonials"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });
  }, []);

  return (
    <section class="container mx-auto w-full max-w-7xl">
      <motion.div
        variants={fadeIn("right", "spring", 0.5, 0.75)}
        className="w-full border-b px-10 pb-2"
      >
        <h3 className="font-Manrope text-xl font-medium uppercase tracking-wide text-yellow-400 antialiased">
          ОТЗЫВЫ МОИХ КЛИЕНТОВ
        </h3>
      </motion.div>

      <div class="rounded-2xl py-6">
        <div className="px-10">
          <Swiper
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {testimonials.map((item) => (
              <SwiperSlide>
                <div className="mx-auto flex h-full max-w-full items-center justify-between gap-x-6 overflow-hidden ">
                  <div className="flex w-[150px] flex-col items-center justify-center gap-4 ">
                    <img
                      loading="lazy"
                      src={urlFor(item.photoUrl)}
                      alt=""
                      className="rounded-full border-2 border-yellow-400 p-1 "
                    />
                    <motion.p
                      variants={textVariant()}
                      className="text-xs text-neutral-500 md:text-base"
                    >
                      {item.title}
                    </motion.p>
                  </div>

                  <div className=" flex h-full w-full flex-col justify-between ">
                    <div className="flex items-center justify-between py-4">
                      <div className="flex flex-col ">
                        <motion.p
                          variants={textVariant()}
                          className="font-Manrope text-sm font-light text-slate-500 md:text-base"
                        >
                          {item.company}
                        </motion.p>
                        <motion.p
                          variants={textVariant()}
                          className="mb-4 font-Manrope text-xs font-light text-slate-400 md:text-sm"
                        >
                          {item.site}
                        </motion.p>
                      </div>

                      <a
                        href={item.site}
                        className="mb-4 hidden w-40 opacity-30 md:flex"
                      >
                        <img
                          src={urlFor(item.imgUrl)}
                          alt={item.title}
                          className="grayscale"
                        />
                      </a>
                    </div>

                    <motion.p
                      variants={textVariant()}
                      className=" font-Manrope text-sm font-light text-slate-700 sm:text-lg  md:text-2xl "
                    >
                      {item.description}
                    </motion.p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2 flex w-full  justify-between lg:mt-10">
            <div className="swiper-button-prev flex cursor-pointer items-center justify-center rounded-full border border-yellow-400 text-3xl text-yellow-400 transition-colors duration-200 ease-in-out hover:bg-yellow-400 hover:text-white md:h-14  md:w-14 md:text-3xl">
              <BiChevronLeft className="" />
            </div>
            <div className="swiper-button-next flex cursor-pointer items-center justify-center rounded-full border border-yellow-400 text-3xl text-yellow-400 transition-colors duration-200 ease-in-out hover:bg-yellow-400 hover:text-white md:h-14  md:w-14 md:text-3xl">
              <BiChevronRight className="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWrapper(Testimonials, "");
