import html5 from "./html5.png";
import css3 from "./css3.png";
import reactjs from "./react.png";
import git from "./git.png";
import js from "./js.png";
import nodejs from "./nodejs.png";
import photoshop from "./photoshop.png";
import sass from "./sass.png";
import tailwind from "./tailwind.png";
import typescript from "./typescript.png";
import framermotion from "./framermotion.svg";
import nextjs from "./nextjs.svg";
import ableton from "./ableton.svg";
import materialui from "./materialui.svg";
import netlify from "./netlify.svg";
import vite from "./vite.svg";
import vscode from "./vscode.svg";
import sanity from "./sanity.svg";
import strapi from "./strapi.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  html5,
  css3,
  reactjs,
  git,
  js,
  nodejs,
  photoshop,
  sass,
  tailwind,
  typescript,
  framermotion,
  nextjs,
  ableton,
  materialui,
  netlify,
  vite,
  vscode,
  sanity,
  strapi,
};
