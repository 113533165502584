import React from "react";

const HeroText = () => {
  return (
    <div className=" flex flex-col overflow-x-hidden whitespace-nowrap bg-gradient-to-r from-[#f5d20c] via-[#e85636] to-[#e96d14] bg-clip-text  text-[58px] font-black text-transparent opacity-80 shadow-black drop-shadow-md sm:text-[100px] md:text-[150px] lg:text-[200px] lg:drop-shadow-xl">
      <span className="font-Manrope text-[50px] tracking-tighter sm:text-[100px] md:text-[130px] lg:text-[150px] ">
        I'm a Frontend
      </span>
    </div>
  );
};

export default HeroText;
