import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";
import SectionWrapper from "../../hoc/SectionWrapper";
import github from "../../assets/svg/github.png";
import { projects } from "../../data/data";
import { useRef } from "react";
import { gsap } from "gsap";
import { FiArrowUpRight } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { Link } from "react-router-dom";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  code_link,
  code_git,
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.3)}>
      <div className="border-2-transparent w-full rounded-2xl border border-slate-400 p-5 hover:border-yellow-400 ">
        <div className="relative z-30 h-[320px] w-full">
          <img
            loading="lazy"
            src={image}
            alt="project_image"
            className="linear h-[320px] w-full rounded-2xl object-cover object-top transition-all duration-[10s] hover:object-bottom"
          />

          <div className="absolute inset-0 -z-10 m-3 flex justify-end gap-2">
            <a
              href={code_link}
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-yellow-400 p-2 transition-colors duration-200 ease-in-out hover:bg-slate-700"
            >
              <GoLinkExternal size={25} className="text-white" />
            </a>
            <div
              onClick={() => window.open(code_link, "_blank")}
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-yellow-400 p-1 transition-colors duration-200 ease-in-out hover:bg-slate-700"
            >
              <a href={code_git} target="blank" rel="noreffer" className="">
                <img
                  src={github}
                  alt="source code"
                  className="h-full w-full object-contain"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="flex h-[200px] flex-col items-stretch justify-between lg:h-[150px]">
          <div className="mt-5">
            <h3 className="font-Manrope text-2xl font-medium text-black">
              {name}
            </h3>
            <p className="mt-2 text-base text-stone-600">{description}</p>
          </div>

          <div className="flex items-center justify-between">
            <div className=" flex gap-2">
              <a
                href={code_link}
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-yellow-400 p-2 transition-colors duration-200 ease-in-out hover:bg-slate-700"
              >
                <GoLinkExternal size={25} className="text-white" />
              </a>
              <div
                onClick={() => window.open(code_link, "_blank")}
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-yellow-400 p-1 transition-colors duration-200 ease-in-out hover:bg-slate-700"
              >
                <a href={code_git} target="blank" rel="noreffer" className="">
                  <img
                    src={github}
                    alt="source code"
                    className="h-full w-full object-contain"
                  />
                </a>
              </div>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {tags.map((tag) => (
                <p
                  key={`${name}-${tag.name}`}
                  className={`text-[14px]  ${tag.color}`}
                >
                  #{tag.name}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Works = () => {
  const el = useRef();

  const mouseEnter = () => {
    gsap.to(el.current, {
      duration: 0.5,
      rotation: "-=45",
      yoyo: true,
      backgroundColor: "#22c55e",
    });
  };

  const mouseLeave = () => {
    gsap.to(el.current, {
      duration: 0.5,
      rotation: "+=45",
      yoyo: true,
      backgroundColor: "#facc15",
    });
  };
  return (
    <section className="container mx-auto w-full max-w-7xl">
      <motion.div
        variants={fadeIn("right", "spring", 0.2, 0.5)}
        className="w-full border-b px-10 pb-2 lg:pt-10"
      >
        <h3 className="font-Manrope text-xl font-medium uppercase tracking-wide text-yellow-400 antialiased">
          МОИ РАБОТЫ
        </h3>
      </motion.div>

      <div className="grid w-full grid-cols-1 place-items-center space-y-4 md:grid-cols-2 lg:space-y-20">
        <Link
          to="/work"
          className="group hidden cursor-pointer flex-col items-center justify-center space-y-2 place-self-center md:flex"
        >
          <motion.div
            ref={el}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            initial={{ borderRadius: 100 }}
            className="parent"
          >
            <div className="child">
              <FiArrowUpRight size={30} className="rotate-45 text-white" />
            </div>
          </motion.div>
          <span className="text-center font-light text-slate-500 md:text-xl lg:text-2xl">
            Портфолио
          </span>
        </Link>

        <div className="lg:pb-10">
          <motion.p
            variants={fadeIn("up", "spring", 0.3, 0.5)}
            className="w-full px-10 text-start text-lg text-slate-700 md:text-xl lg:text-start lg:text-2xl"
          >
            Ниже представлены мои последние работы, которые демонстрируют мои
            навыки и были сданы клиенту. <br /> С остальными моими проектами
            можно ознакомится в разделе Портфолио.
          </motion.p>
        </div>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4 px-4 lg:mt-10 lg:grid-cols-2 lg:gap-10 ">
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>

      <Link to="/work">
        <motion.div
          variants={fadeIn("up", "spring", 0.3, 0.5)}
          className="my-20 flex items-center justify-center"
        >
          <button className="rounded-xl bg-yellow-400 px-20 py-3 transition-colors duration-500 ease-in-out hover:bg-green-500 hover:text-white ">
            Посмотреть все работы
          </button>
        </motion.div>
      </Link>
    </section>
  );
};

export default SectionWrapper(Works, " works");
