import React from "react";
import { motion } from "framer-motion";
import StarWrapper from "../../hoc/SectionWrapper";
import HeroText from "./HeroText";
import { textVariant } from "../../utils/motion";
import { fadeIn } from "../../utils/motion";
import ArrowDown from "./ArrowDown";

const Hero = () => {
  return (
    <section className="relative mx-auto h-[20vh] max-w-7xl px-10 sm:h-[30vh] md:h-[60vh] lg:h-[80vh]">
      <div
        data-scroll
        data-scroll-speed="10"
        className="absolute left-[-30%] top-[30%] -z-[1000] hidden h-64 w-64 bg-plx1 bg-cover  bg-no-repeat blur-[3px] lg:block"
      />
      <div
        data-scroll
        data-scroll-speed="6"
        className="absolute bottom-80 right-[0%] -z-[1000] hidden h-48 w-48 bg-plx2 bg-cover bg-no-repeat blur-sm lg:block"
      />
      <div
        data-scroll
        data-scroll-speed="3"
        className=" absolute left-[10%]  top-0 -z-[1000] hidden h-48 w-48 bg-plx3 bg-cover bg-no-repeat blur-[2px] lg:block"
      />
      <div
        data-scroll
        data-scroll-speed="8"
        className="   absolute bottom-48 left-[20%] -z-[1000] hidden h-48 w-48 bg-plx4 bg-cover bg-no-repeat blur-[10px] lg:block"
      />
      <div
        data-scroll
        data-scroll-speed="4"
        className="   absolute right-48 top-0 -z-[1000] hidden h-48 w-48 bg-plx5 bg-cover bg-no-repeat blur-[2px] lg:block"
      />
      <div className="flex flex-col items-center justify-center ">
        <motion.p
          variants={textVariant()}
          className="text-center font-Manrope text-sm text-slate-500 antialiased md:text-base"
        >
          RAFAIL ASKEROV aka RUFF <br />
        </motion.p>
        <motion.p
          variants={textVariant(0.3)}
          className="text-center text-xs text-slate-500 antialiased md:text-sm"
        >
          PERSONAL WEB-SITE
        </motion.p>

        <motion.div
          variants={fadeIn("up", "spring", 0.3, 0.9)}
          className="relative z-50 mx-auto mt-10 flex max-w-7xl justify-center md:mt-40 "
        >
          <HeroText />

          <div
            className="absolute -bottom-6 -right-4 -z-50 -rotate-12 hover:z-50 
          md:-bottom-8 
          md:right-0
          lg:-bottom-10 
          lg:right-0"
          >
            <span className="mx-4 font-mrDafoe text-[48px] text-slate-500 sm:text-[100px] md:text-[100px] lg:text-[120px] ">
              Developer
            </span>
          </div>
        </motion.div>
      </div>
      <ArrowDown />
    </section>
  );
};

export default StarWrapper(Hero, "");
