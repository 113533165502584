import React, { useState } from "react";
import images from "../assets/images/skills";
import Marquee from "react-fast-marquee";

const mySkills = [
  {
    name: "HTML5",
    icon: images.html5,
    skill: 86,
  },
  {
    name: "CSS3",
    icon: images.css3,
    skill: 80,
  },
  {
    name: "JavaScript",
    icon: images.js,
    skill: 56,
  },
  {
    name: "ReactJS",
    icon: images.reactjs,
    skill: 68,
  },
  {
    name: "Tailwind",
    icon: images.tailwind,
    skill: 90,
  },
  {
    name: "FramerMotion",
    icon: images.framermotion,
    skill: 50,
  },
  {
    name: "Git",
    icon: images.git,
    skill: 38,
  },
  {
    name: "Photoshop",
    icon: images.photoshop,
    skill: 70,
  },
  {
    name: "Sass",
    icon: images.sass,
    skill: 46,
  },
  {
    name: "Typescript",
    icon: images.typescript,
    skill: 28,
  },
  {
    name: "NextJS",
    icon: images.nextjs,
    skill: 34,
  },
  {
    name: "NodeJS",
    icon: images.nodejs,
    skill: 37,
  },
  {
    name: "Ableton",
    icon: images.ableton,
    skill: 75,
  },
  {
    name: "Material UI",
    icon: images.materialui,
    skill: 37,
  },
  {
    name: "Netlify",
    icon: images.netlify,
    skill: 37,
  },
  {
    name: "Vite",
    icon: images.vite,
    skill: 37,
  },
  {
    name: "VScode",
    icon: images.vscode,
    skill: 37,
  },
  {
    name: "Sanity",
    icon: images.sanity,
    skill: 37,
  },
  {
    name: "Strapi",
    icon: images.strapi,
    skill: 37,
  },
];

const Skills = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="flex w-full py-2 md:py-20 ">
      <Marquee gradientColor={[248, 250, 252]} pauseOnHover>
        {mySkills.map((item) => (
          <div
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            className="children  flex flex-col items-center justify-center text-center group-hover:opacity-100"
          >
            <div className="w-20 p-4 md:mx-6 lg:w-32">
              <img
                src={item.icon}
                alt={item.name}
                className="h-[50px] max-w-[100px] opacity-60 grayscale transition duration-200 ease-in-out hover:scale-105 hover:opacity-100 hover:grayscale-0 md:h-[90px]"
              />
            </div>
            <div
              className={`h-6 text-center text-sm font-light ${
                show
                  ? "opacity-100 transition duration-200 ease-in-out"
                  : "opacity-0"
              }`}
            >
              {item.name}
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Skills;
