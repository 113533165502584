import React, { Fragment } from "react";
import Hero from "../components/MainPage/Hero";
import About from "../components/MainPage/About";
import Contact from "../components/Contact";
import Works from "../components/MainPage/Works";
import Testimonials from "../components/MainPage/Testimonials";
import Social from "../components/Social";
import Skills from "../components/Skills";
import { Footer } from "../components/Footer";

const MainPage = () => {
  return (
    <Fragment>
      <Hero />
      <Social />
      <Skills />
      <About />
      <Works />
      <Testimonials />
      <Contact />
      <Footer />
    </Fragment>
  );
};

export default MainPage;
